import React, { useEffect, useState } from 'react';
import css from './HeroSection.module.css';

const HeroSection = () => {
  const [selectedContent, setSelectedContent] = useState(1);
  const contentButtons = [
    {
      label: 'For Self-Builders',
      onClick: () => setSelectedContent(1),
      isSelected: selectedContent === 1,
    },
    {
      label: 'MMC Housebuilders',
      onClick: () => setSelectedContent(2),
      isSelected: selectedContent === 2,
    },
    {
      label: 'For MMC Contractors/Consultants',
      onClick: () => setSelectedContent(3),
      isSelected: selectedContent === 3,
    },
  ];

  const slidesContent = [
    {
      id: 1,
      content: (
        <div className={css.slide}>
          <h4 className={css.title}>Find out how The Modern Builder Marketplace can help you?</h4>
          <p className={css.slideTitle}>For Self-Builders</p>
          <div className={css.slideList}>
            <p>
              <div className={css.bullet}></div>A wide range of MMC house suppliers
            </p>
            <p>
              <div className={css.bullet}></div>Our approved MMC contractors will help with project
              delivery
            </p>
            <p>
              <div className={css.bullet}></div>Control your costs
            </p>
            <p>
              <div className={css.bullet}></div>Manage payments
            </p>
            <p>
              <div className={css.bullet}></div>Streamline communications{' '}
            </p>
            <p>
              <div className={css.bullet}></div>File management
            </p>
            <p>
              <div className={css.bullet}></div>Digital signatories
            </p>
            <p>
              <div className={css.bullet}></div>Track projects
            </p>
          </div>

          <div className={css.buttonsWrapper}>
            <a
              className={css.ctaButton}
              href="https://calendly.com/themodernbuilder/discovery"
              target="_blank"
            >
              Book a call with us
            </a>
          </div>
        </div>
      ),
    },
    {
      id: 2,
      content: (
        <div className={css.slide}>
          <h4 className={css.title}>Find out how The Modern Builder Marketplace can help you?</h4>
          <p className={css.slideTitle}>For MMC House Builders</p>
          <div className={css.slideList}>
            <p>
              <div className={css.bullet}></div>Display your best home designs
            </p>
            <p>
              <div className={css.bullet}></div>Will bring real construction opportunities
            </p>
            <p>
              <div className={css.bullet}></div>Manage payments
            </p>
            <p>
              <div className={css.bullet}></div>File management
            </p>
            <p>
              <div className={css.bullet}></div>Digital signatories
            </p>
            <p>
              <div className={css.bullet}></div>Track enquiry stage of projects
            </p>
            <p>
              <div className={css.bullet}></div>Manage and store all project information on one
              digital platform
            </p>
          </div>
          <div className={css.buttonsWrapper}>
            <a
              className={css.ctaButton}
              href="https://calendly.com/themodernbuilder/discovery"
              target="_blank"
            >
              Book a call with us
            </a>
          </div>
        </div>
      ),
    },
    {
      id: 3,
      content: (
        <div className={css.slide}>
          <h4 className={css.title}>Find out how The Modern Builder Marketplace can help you?</h4>
          <p className={css.slideTitle}>For MMC Contractors/Consultants</p>
          <div className={css.slideList}>
            <p>
              <div className={css.bullet}></div>Price up ready to go projects
            </p>
            <p>
              <div className={css.bullet}></div>Monitor project progress
            </p>
            <p>
              <div className={css.bullet}></div>Point of sale advertising
            </p>
          </div>
          <div className={css.buttonsWrapper}>
            <a
              className={css.ctaButton}
              href="https://calendly.com/themodernbuilder/discovery"
              target="_blank"
            >
              Book a call with us
            </a>
          </div>
        </div>
      ),
    },
  ];

  const focusedContent = slidesContent.find(c => c.id === selectedContent)?.content;
  return (
    <div className={css.wrapper}>
      <div className={css.sectionTop}>
        {/* <img src={homepagePic} className={css.imageRight} /> */}
        <iframe
          id={'videoIframe'}
          className={css.youtubeIframe}
          src="https://player.vimeo.com/video/964512230?h=61121ffd26&autoplay=1&muted=1"
          title="Video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        {/* <iframe
          className={css.youtubeIframe}
          src="https://www.youtube.com/embed/EyHIIpJaOeA?si=_1seH7-eO8c6zu2K&autoplay=1&mute=1"
          title="YouTube video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe> */}

        <div className={css.sectionContent}>
          <div className={css.slide}>
            <h4 className={css.title}>Find out how The Modern Builder Marketplace can help you?</h4>
            <div className={css.slideList}>
              <span>
                <div className={css.bullet}></div>Self and Custom Builders
              </span>
              <span>
                <div className={css.bullet}></div>Modern Builders and Architects
              </span>
              <span>
                <div className={css.bullet}></div>Modern Contractors and Consultants
              </span>
            </div>

            <div className={css.buttonsWrapper}>
              <a
                className={css.ctaButton}
                href="https://calendly.com/themodernbuilder/discovery"
                target="_blank"
              >
                Book a call with us
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={css.buttonsSection}>
        {contentButtons.map(b => (
          <div
            onClick={b.onClick}
            className={b.isSelected ? css.switchButtonSelected : css.switchButton}
          >
            {b.label}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default HeroSection;
