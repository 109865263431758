import React, { useState } from 'react';
import css from './QuestionSection.module.css';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { fb, instagram, linkedIn, mail, map, phone } from './utils';
import { FieldCheckboxGroup, FieldTextInput, Form, Button } from '../../../../components';
import { addToFirestoreDoc } from '../../../../util/firebase';
import { sendAnalyticsEvent } from '../../../../util/analytics';

const generate8DigitId = () => {
  const timestamp = Date.now();
  const id = (timestamp % 100000000).toString().padStart(8, '0');
  return id;
};

const QuestionSection = () => {
  const [inProgress, setInProgress] = useState(false);
  const [done, setDone] = useState(false);
  const handleFormSubmit = values => {
    sendAnalyticsEvent('button_click', 'submitted_contact_form');

    setInProgress(true);
    const { fName, lName, email, message, terms } = values;

    const documentId = `${email} - ${generate8DigitId()}`;
    //TODO move the DB path to env
    return addToFirestoreDoc('landing-page-inquiries', documentId, {
      firstName: fName,
      lastName: lName,
      email,
      message,
      submitDate: new Date().toString(),
      inquiryType: 'landing-page',
    })
      .then(resp => {
        setInProgress(false);
        setDone(true);
      })
      .catch(e => {
        setDone(true);
        setInProgress(false);
      });
  };
  return (
    <div className={css.wrapper}>
      <div className={css.sectionLeft}>
        <h1>Got a question? Speak to a member of the team</h1>

        <p>
          Schedule a call with a self-build specialist to discuss your project and learn more about
          how The Modern Builder can help you
        </p>

        <div className={css.contactLine}>
          {phone} <span>07983233876</span>
        </div>
        <div className={css.contactLine}>
          {map} <span>Manchester, UK</span>
        </div>

        <div className={css.contactLine}>
          {mail} <span>info@themodernbuilder.co.uk</span>
        </div>
        <div className={css.contactLine}>
          <a href="https://www.facebook.com/profile.php?id=61555715476988" target="_blank">
            {fb}
          </a>
          <a href="https://www.instagram.com/the.modern.builder/" target="_blank">
            {instagram}
          </a>

          <a
            href="https://www.linkedin.com/company/the-modern-builder/?viewAsMember=true"
            target="_blank"
          >
            {linkedIn}
          </a>
        </div>
      </div>
      <div className={css.sectionRight}>
        <FinalForm
          mutators={{ ...arrayMutators }}
          initialValues={{}}
          onSubmit={handleFormSubmit}
          render={fieldRenderProps => {
            const { handleSubmit, values } = fieldRenderProps;
            const { fName, lName, email, message, terms } = values;

            const submitDisabled = !fName || !lName || !email || !message || !terms;

            return (
              <Form className={css.formWrapper} onSubmit={handleSubmit}>
                <div className={css.name}>
                  <FieldTextInput
                    className={css.field}
                    type="text"
                    id={'fName'}
                    name="fName"
                    label={'First name'}
                    placeholder={'Type here...'}
                  />

                  <FieldTextInput
                    className={css.field}
                    type="text"
                    id={'lName'}
                    name="lName"
                    label={'Last name'}
                    placeholder={'Type here...'}
                  />
                </div>

                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={'email'}
                  name="email"
                  label={'Email'}
                  placeholder={'Type here...'}
                />

                <FieldTextInput
                  className={css.field}
                  type="textarea"
                  id={'message'}
                  name="message"
                  label={'Message'}
                  placeholder={'Type here...'}
                />

                <FieldCheckboxGroup
                  name="terms"
                  id="terms-accepted"
                  optionLabelClassName={css.finePrint}
                  options={[
                    {
                      key: 'tos-and-privacy',
                      label: (
                        <span>
                          I agree to The Modern Builder’s friendly{' '}
                          <a target="_blank" href="/privacy-policy">
                            privacy policy
                          </a>
                          .
                        </span>
                      ),
                    },
                  ]}
                />
                <br />
                <Button
                  type="submit"
                  inProgress={inProgress}
                  disabled={submitDisabled}
                  ready={done}
                >
                  Submit
                </Button>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
};

export default QuestionSection;
