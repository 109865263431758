import React from 'react';
import people1 from './people1.png';
import people2 from './people2.png';
import css from './CardsSection.module.css';
import { NamedLink } from '../../../../components';
import CompaniesLogs from '../CompaniesLogs/CompaniesLogs';

const CardsSection = ({ isLoggedIn }) => {
  return (
    <div className={css.wrapper}>
      <div className={`${css.card} ${isLoggedIn && css.cardMarginAdjustment}`}>
        <div className={css.cardTop}>
          <div className={css.cardTopL}>For Self & Custom Builders</div>
          <img src={people1} className={css.cardImage} />
        </div>
        <div className={`${css.cardBottom} ${isLoggedIn && css.reducedHeightCardBottom}`}>
          <div className={css.cardBottomText}>
            To get started on your self build project, sign up to create a mood board and receive a
            FREE construction estimate
          </div>
          {!isLoggedIn && (
            <NamedLink className={css.buttonWhite} name="SignupPage">
              Create Account
            </NamedLink>
          )}
        </div>
      </div>

      <div className={`${css.cardSecond} ${isLoggedIn && css.cardSecondMarginAdjustment}`}>
        <div className={css.cardTopS}>
          <div className={css.cardTopL}>For Modern Builders and Architects</div>
          <img src={people2} className={css.cardImageS} />
        </div>
        <div className={`${css.cardBottomS} ${isLoggedIn && css.reducedHeightCardBottom}`}>
          <div className={css.cardBottomText}>
            To connect with customers, sign up for free to list images and designs from previous
            projects.
          </div>
          {!isLoggedIn && (
            <NamedLink className={css.buttonWhite} name="SignupPage">
              Create Account
            </NamedLink>
          )}
        </div>
      </div>

      <CompaniesLogs />
    </div>
  );
};

export default CardsSection;
