import React, { useEffect, useState } from 'react';
import image from './image.jpg';
import imageMobile from './imageMobile.png';
import css from './PopupContent.module.css';
import { Form as FinalForm } from 'react-final-form';
import { Button, FieldCheckboxGroup, FieldTextInput, Form } from '../../../../components';
import ebook from './TMB_Ebook.pdf';
import arrayMutators from 'final-form-arrays';
import Cookies from 'js-cookie';
import { SocialLoginButtonsMaybe } from '../../../AuthenticationPage/AuthenticationPage';
import { addToFirestoreDoc } from '../../../../util/firebase';
import { sendAnalyticsEvent } from '../../../../util/analytics';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 1025;

const generate8DigitId = () => {
  const timestamp = Date.now();
  const id = (timestamp % 100000000).toString().padStart(8, '0');
  return id;
};

const getAuthInfoFromCookies = () => {
  return Cookies.get('st-authinfo')
    ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
    : null;
};

const PopupContent = props => {
  const { location } = props;
  const [error, setError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [authInfo, setAuthInfo] = useState(getAuthInfoFromCookies());

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const closeButton = document.getElementById('popup_close_button');
      document.getElementById('popup_wrapper').append(closeButton);
      closeButton.style.display = 'block';
    }
  }, []);

  const locationFrom = location.state?.from || null;
  const authinfoFrom = authInfo?.from || null;
  const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;

  const handleFormSubmit = values => {
    sendAnalyticsEvent('button_click', 'download_ebook');
    setInProgress(true);
    const documentId = generate8DigitId();
    return addToFirestoreDoc('ebook-download-list', documentId, {
      name: values.name,
      email: values.email,
      submitDate: new Date().toString(),
    })
      .then(resp => {
        setError(false);
        // Trigger download
        const link = document.createElement('a');
        link.href = ebook;
        link.download = 'TMB_Ebook.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        document.getElementById('popup_close_button')?.click();
        setInProgress(false);
      })
      .catch(e => {
        console.log(e);
        setError(true);
        setInProgress(false);
      });
  };

  return (
    <div className={css.wrapper} id="popup_wrapper">
      <img src={isMobile ? imageMobile : image} className={css.image} />
      <div className={css.formWrapper}>
        <p className={css.title}>
          Leave your details below for a <span className={css.redText}>free ebook</span> on how to
          start building your dream home.
        </p>

        <FinalForm
          mutators={{ ...arrayMutators }}
          onSubmit={handleFormSubmit}
          render={fieldRenderProps => {
            const { handleSubmit, values } = fieldRenderProps;
            const submitDisabled = !values.name || !values.email || !values.privacy;

            return (
              <Form onSubmit={handleSubmit}>
                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={'name'}
                  name="name"
                  label={'Name*'}
                  placeholder={'Enter your name'}
                />

                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={'email'}
                  name="email"
                  label={'Email*'}
                  placeholder={'Enter your email'}
                />

                <FieldCheckboxGroup
                  name="privacy"
                  id="privacy"
                  options={[
                    {
                      key: 'privacy',
                      label: (
                        <span className={css.privacy}>
                          I agree to The Modern Builder’s friendly{' '}
                          <a target="_blank" href="/privacy-policy">
                            privacy policy.
                          </a>
                        </span>
                      ),
                    },
                  ]}
                />
                {error && (
                  <p className={css.error}>Woops! Something went wrong, please try again.</p>
                )}
                <Button
                  className={css.submit}
                  type="submit"
                  inProgress={inProgress}
                  disabled={submitDisabled}
                >
                  Download ebook
                </Button>
              </Form>
            );
          }}
        />

        <SocialLoginButtonsMaybe
          isLogin={false}
          showFacebookLogin={true}
          showGoogleLogin={true}
          from={from}
        />

        <p className={css.footprint}>
          Sign up and a member of our team will be in contact to help you progress your self-build
          dreams
        </p>
      </div>
    </div>
  );
};

export default PopupContent;
