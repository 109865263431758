import React from 'react';
import css from './HowItWorksSection.module.css';
import { NamedLink } from '../../../../components';
import rectangle1 from './rectangle1.png';
import rectangle2 from './rectangle2.jpg';
import checkIcon from './checkIcon.jpg';

const HowItWorksSection = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.sectionLeft}>
        <h1>
          Join <span>15,000+</span> people who will self-build in the UK over the next 12-months
        </h1>

        <p>
          Building your own home can be a daunting task. From knowing where to start through to
          managing your self-build, you can quickly feel overwhelmed. At The Modern Builder, we
          believe building your dream home should be easy and fun, not overwhelming! Our platform
          helps you:
        </p>

        <div className={css.checksSection}>
          <div className={css.checkItem}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Navigate the self-build process</span>
          </div>
          <div className={css.checkItem}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Control your costs</span>
          </div>
          <div className={css.checkItem}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Connect with trusted suppliers</span>
          </div>
          <div className={css.checkItem}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Manage your project</span>
          </div>
        </div>
        <NamedLink className={css.pinkButton} name="HowItWorksPage">
          How it Works
        </NamedLink>
      </div>
      <div className={css.sectionRight}>
        <img className={css.imageTop} src={rectangle1} />
        <img className={css.imageBottom} src={rectangle2} />
      </div>
    </div>
  );
};

export default HowItWorksSection;
