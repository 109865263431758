import React from 'react';
import css from './DiscoverSection.module.css';
import { NamedLink } from '../../../../components';
import rectangle1 from './rectangle3.png';
import rectangle2 from './rectangle4.jpg';
import checkIcon from './checkIcon.jpg';

const DiscoverSection = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.sectionFirst}>
        <img className={css.imageBottom} src={rectangle2} />
        <img className={css.imageTop} src={rectangle1} />
      </div>

      <div className={css.sectionSecond}>
        <h1>
          Discover the benefits of <span>Modern Methods of Construction</span>
        </h1>

        <p>
          Modern Methods of Construction (MCC) are transforming the way homes are being built in the
          UK. Over 50% of self-build projects now take advantage of MMC to benefit from:
        </p>

        <div className={css.checksSection}>
          <div className={css.checkItem}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Reduced environmental impact</span>
          </div>
          <div className={css.checkItem}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Control overall build costs</span>
          </div>
          <div className={css.checkItem}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Accelerated build times</span>
          </div>
        </div>

        <p className={css.infoText}>
          Thanks to Modern Methods of Construction, self-building is more accessible than ever
          before.
        </p>

        <div className={css.sectionFirstMobile}>
          <img className={css.imageBottom} src={rectangle2} />
          <img className={css.imageTop} src={rectangle1} />
        </div>

        <NamedLink className={css.pinkButton} name="MMCPage">
          Discover MMC
        </NamedLink>
      </div>
    </div>
  );
};

export default DiscoverSection;
